.App {
  min-height: 70vh;
}

.word-card {
  padding: 24px;
  border: 1px solid #e8ebf2;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  margin-bottom: 16px;
}

.main-word {
  margin-bottom: 4px;
  letter-spacing: 1px;
  font-size: 1.2em;
}

.word-pronouncination {
  font-size: 0.8em;
  opacity: 0.8;
  margin-left: 16px;
}

.word-desc {
  margin-top: 16px;
  font-weight: 200;
}

.text-container {
  position: relative;
  max-height: 100px; /* Set the height limit */
  overflow: hidden; /* Hide overflow */
  transition: max-height 0.5s ease; /* Smooth transition for expanding */
}

.text-content {
  margin: 0;
}

.show-more-link {
  display: none; /* Initially hidden */
  position: absolute;
  bottom: 0;
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)); /* Gradient effect for better UI */
  padding-left: 10px;
  text-decoration: none;
  font-weight: bold;
}

.text-container.overflowed .show-more-link {
  display: block; /* Show the link if the text overflows */
}

.text-container.expanded {
  max-height: none; /* Expand text when "Show More" is clicked */
}

.text-container.expanded .show-more-link {
  display: none; /* Hide the "Show More" link when text is expanded */
}





.word-search-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.word-search-grid {
  display: grid;
  gap: 2px;
  width: 100%;
  max-width: 90vmin;
  max-height: 90vmin;
  box-sizing: border-box;
}

.word-search-cell {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(8px + 2vmin);
  text-transform: uppercase;
  user-select: none;
  aspect-ratio: 1;
}

form label {
  font-size: 0.8em;
}

input {
  padding: 8px !important;
  letter-spacing: 1px !important;
  border: 3px solid #000 !important;
  border-radius: 8px !important;
  outline: none !important;
  outline-style: none !important;
  font-size: 1.2em !important;
  width: 100% !important;
}

input:focus {
  outline: none !important;
  outline-style: none !important;
  --tw-ring-shadow: unset;
  --tw-ring-color: unset;
  box-shadow: unset !important;
}

.input-group {
  margin-bottom: 16px !important;
}