@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap');

/* Ensure footer doesn't overlap with content on iOS devices */
@supports (padding: max(0px)) {
  .bottom-nav {
    padding-bottom: calc(env(safe-area-inset-bottom) + 0px);
  }
}

.inter-ver {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

.inter-bold {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}

.m-plus-rounded-1c-regular {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.m-plus-rounded-1c-extrabold {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.m-plus-rounded-1c-black {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.m-plus-rounded-1c-medium {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 500;
  font-style: normal;
}



body {
  margin: 0;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 800;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 17px;
font-stretch: 100%;
 color: #4b4b4b;
 background-color: #FFF;
}

.title {
  font-size: 32px;
  line-height: 40px;
  margin: 4px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rubik-mono {
  font-family: "Rubik Mono One", monospace;
  font-weight: 400;
  font-style: normal;
}

/* Card */
.card-sub-text {
  color: hsl(0deg 0% 0% / 60%);
}

.card-stats {
  display: grid;
  grid-template-columns: max-content min-content;
  column-gap: 2em;
}

.colour-1 {
  width: 100%;
  height: 20vh;
  background-color: #E6C153;
  color: #FFF;
}

.bg-main {
  background-color: #FFF;
}

.bg-on-main {
  color: #FFF;
}

.bg-secondary {
  background-color: #9189de;
}